<template>
  <div class="email-wrapper">
    <SInput
      v-model.trim="emailValue"
      v-bind="componentAttrs"
      variant="field-box"
      @focus="handleFocus"
      @blur="handleBlur"
      @update:model-value="handleInput"
      @click="handleClick"
    />
    <ul
      v-show="!!recommendEmailList.length && showRecList"
      class="email-wrapper__rec"
    >
      <li
        v-for="email in recommendEmailList" 
        :key="email"
        @click.stop.prevent="handleSelect(email)"
      >
        {{ email }}
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'
import { SInput } from '@shein-aidc/sui-input/mobile'
const baseRecommendEmails = [
  'gmail.com',
  'hotmail.com',
  'yahoo.es',
  'hotmail.es',
  'yahoo.com',
  'telefonica.net',
  'outlook.com',
  'msn.com',
  'live.com',
  'icloud.com'
]
const sleep = (timeout = 100) => new Promise(r => setTimeout(r, timeout))
export default defineComponent({
  components: {
    SInput
  },
  inheritAttrs: false,
  props: {
    showRecList: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emailValue: '',
      isFocus: false,
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    recommendEmailList() {
      const atReg = /(.*)@(.*)/
      if (!this.isFocus || !this.emailValue ) return [] // 无内容不展示
      const matchAt = this.emailValue.match(atReg)
      if (!!baseRecommendEmails.find(v => v === matchAt?.[2])) return [] // 已属于推荐邮箱则不展示
      if (matchAt && !matchAt?.[1]) return [] // 以@开头不展示推荐
      return baseRecommendEmails.map(domain => {
        if (!matchAt) return `${this.emailValue}@${domain}` // 没有@则完整填充
        if (domain.indexOf(matchAt?.[2]) === 0) return `${matchAt[1]}@${domain}`
        return false
      }).filter(Boolean)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.emailValue = val
      }
    },
    emailValue() {
      this.$emit('update:model-value', this.emailValue)
    }
  },
  emits: ['focus', 'blur', 'update:model-value'],
  methods: {
    handleSelect(val) {
      this.emailValue = val
    },
    handleFocus() {
      this.isFocus = true
      this.$emit('focus')
    },
    handleInput() {
      this.isFocus = true
    },
    handleClick() {
      this.isFocus = true
    },
    async handleBlur() {
      await sleep()
      this.isFocus = false
      this.$emit('blur')
    }
  },
})
</script>

<style scoped lang="less">
.email-wrapper {
  position: relative;

  &__rec {
    list-style: none;
    max-height: 352/75rem;
    z-index: @zindex-transform1 + 1;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8/75rem;
    padding: 16/75rem 0;
    box-shadow: 0 2/75rem 8/75rem 6/75rem rgba(0,0,0,0.08);
    background-color: @sui_color_white;
    overflow: auto;
    .font-dpr(26px);
    color: @sui_color_gray_dark1;
    li {
      line-height: 1.2;
      list-style: none;
      .flexbox();
      .space-between();
      .align-center();
      cursor: pointer;
      background-color: @sui_color_white;
      padding: 24/75rem 32/75rem;
      &:active {
        background-color: @sui_color_gray_weak2;
      }
    }
  }
}
</style>
